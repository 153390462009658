















































































































































import {
	type PropType,
	computed,
	defineComponent,
	reactive,
	ref,
	watch,
} from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { format, isBefore, subDays } from "date-fns";
import { clubApi, coachApi, reserveerApi } from "@/lib/backend";
import type { Afspraak } from "@/lib/backend/coach.api";
import { APPOINTMENT_MAP } from "@/lib/constants/options";
import { formatErrorMessage } from "@/lib/formatErrorMessage";
import { getCoachName } from "@/lib/getCoachName";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { queryOptions_coaches } from "@/lib/query/functions/coaches";
import { queryOptions_gyms } from "@/lib/query/functions/gyms";
import { toast } from "@/lib/vue2-sonner";
import { logger } from "@/logger";
import { useAuthStore } from "@/pinia/auth";
import { usePopupStore } from "@/pinia/popup";
import type { RouteProps } from "@/router/types";
import { getDateFromDatum, getDateFromDatumAndTijd } from "@/utils/date";

export default defineComponent({
	props: {
		memberId: {
			type: Number as PropType<RouteProps<"member">["memberId"]>,
			required: true,
		},
	},
	setup(props, { root }) {
		const TOAST_ID = "afspraak-inplannen";

		const authStore = useAuthStore();
		const popupStore = usePopupStore();

		const data = reactive({
			comments: "",
			toggles: Object.entries(APPOINTMENT_MAP).map(([slug, appointment]) => ({
				slug,
				...appointment,
				enabled: false,
			})),
		});

		const { data: memberBasicInfo } = useQuery({
			queryKey: ["member", props.memberId, "basic-info"] as const,
			queryFn: async (context) =>
				await clubApi.lid
					.getLidMetStatus(context.queryKey[1], { signal: context.signal })
					.then((response) => response.data),
		});

		const { data: memberInfo } = useQuery({
			queryKey: ["member", props.memberId, "info"] as const,
			queryFn: async (context) =>
				await clubApi.sportersDashboard
					.getLidInfo(context.queryKey[1], { signal: context.signal })
					.then((response) => response.data),
		});

		const { id: currentGymId } = useCurrentGym();
		const { data: gyms } = useQuery(queryOptions_gyms());

		const overridenGymId = ref<number | undefined>(undefined);
		const gymId = computed<number>({
			get() {
				return overridenGymId.value || memberBasicInfo.value?.vestiging || currentGymId.value;
			},
			set(value) {
				overridenGymId.value = value;
			},
		});
		const gym = computed(() => gyms.value?.find((gym) => gym.id === gymId.value));

		const { data: coaches } = useQuery(
			queryOptions_coaches(computed(() => ({ gymId: gymId.value }))),
		);

		const overridenCoachId = ref<number | null | undefined>(undefined);
		const coachId = computed<number | null | undefined>({
			get() {
				if (overridenCoachId.value || overridenCoachId.value === null) {
					return overridenCoachId.value;
				}

				return (
					memberInfo.value?.coachLid ||
					(authStore.userClub?.vestiging === gym.value?.slug
						? authStore.userClub?.coachId || undefined
						: undefined)
				);
			},
			set(value) {
				overridenCoachId.value = value;
			},
		});

		const date = ref(new Date());
		const disabledDates = ref({ to: subDays(new Date(), 1) });

		const queryKey = computed(
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
			() => ["afspraken", gym.value?.slug!, format(date.value, "MM-yyyy")] as const,
		);
		const enabled = computed(() => !!gym.value?.slug);
		const { status, data: monthDetail } = useQuery({
			queryKey,
			queryFn: async (context) =>
				await coachApi.api
					.appointmentsMonthDetail(context.queryKey[1], context.queryKey[2], {
						signal: context.signal,
					})
					.then((response) => response.data),
			enabled,
		});
		const slots = computed(() => monthDetail.value?.[format(date.value, "dd-MM-yyyy")] ?? []);

		const selectedSlot = ref<Afspraak | undefined>(undefined);

		const showKickOff = computed(() => !memberInfo.value?.onboardingAfgerond);

		watch(gymId, () => {
			coachId.value = null;
			selectedSlot.value = undefined;
		});
		watch(date, () => (selectedSlot.value = undefined));

		async function submit() {
			try {
				const afspraakId = selectedSlot.value?.afspraakId;

				if (!selectedSlot.value || !afspraakId) {
					throw new Error("Selecteer een afspraak tijdstip");
				}

				if (data.toggles.every((toggle) => !toggle.enabled)) {
					throw new Error("Selecteer ten minste één afspraak reden");
				}

				return popupStore.open({
					title: "Let op!",
					body: `Wil je de volgende afspraak maken:
								<p>
									<b>${selectedSlot.value.datum}</b> om <b>${selectedSlot.value.slot}</b> in
									<b>${gym.value?.naam}</b>
									voor
									<b>${memberInfo.value?.voornaam} ${memberInfo.value?.achternaam}</b>
								</p>
								Tijdens de afspraak worden de volgende punten behandeld:
								<ul>
									${data.toggles
										.filter((toggle) => toggle.enabled)
										.map((toggle) => `<li><b>${toggle.text}</b></li>`)
										.join("")}
								</ul>
								Met de volgende opmerking:
								<p>
									<b>${data.comments ? data.comments : "Geen opmerkingen."}</b>
								</p>`,
					buttons: {
						cancel: "Annuleren",
						confirm: "Bevestigen",
					},
					callback: async () => {
						try {
							toast.loading("Afspraak inplannen...", { id: TOAST_ID });

							const response = await coachApi.api.fitnessafsprakenAanwezigheidCreate(afspraakId, {
								lidId: props.memberId,
								lidNaam: `${memberInfo.value?.voornaam} ${memberInfo.value?.achternaam}`,
								status: "aangemeld",
								opmerkingen: data.comments,
								isTemporaryMember: false,
								doelOpstellen:
									data.toggles.find((toggle) => toggle.slug === "doelOpstellen")?.enabled ?? false,
								meting: data.toggles.find((toggle) => toggle.slug === "meting")?.enabled ?? false,
								kickOff: data.toggles.find((toggle) => toggle.slug === "kickOff")?.enabled ?? false,
								personalTraining:
									data.toggles.find((toggle) => toggle.slug === "personalTraining")?.enabled ??
									false,
								trainingsSchema:
									data.toggles.find((toggle) => toggle.slug === "trainingsSchema")?.enabled ??
									false,
							});

							if (coachId.value) {
								await reserveerApi.fitnessafspraken.wijzigAfspraak({
									...response.data,
									status: "aankomend",
									trainerId: coachId.value,
								});
							}

							toast.success("Afspraak ingepland", { id: TOAST_ID });
							root.$router.push({ name: "Gebruiker" });
						} catch (error) {
							logger.error("Afspraak Inplannen", error);
							toast.error(formatErrorMessage(error), { id: TOAST_ID });
							throw error;
						}
					},
				});
			} catch (error) {
				logger.error("Afspraak Inplannen", error);
				toast.error(formatErrorMessage(error), { id: TOAST_ID });
				throw error;
			}
		}

		return {
			getDateFromDatum,
			getDateFromDatumAndTijd,
			isBefore,
			getFullName: getCoachName,
			showKickOff,
			memberBasicInfo,
			memberInfo,
			gymId,
			gyms,
			coachId,
			coaches,
			disabledDates,
			date,
			status,
			slots,
			selectedSlot,
			data,
			submit,
		};
	},
});
